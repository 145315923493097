import us from './flag/us.svg';
import germany from './flag/germany.svg';
import spain from './flag/spain.svg';
import saudi from './flag/saudi.png';
import chinese from './flag/china.svg';
import israel from './flag/israel.svg';

export default [
  {
    id: 'en',
    language: 'en',
    icon: us,
  },
  {
    id: 'de',
    language: 'de',
    icon: germany,
  },
];
