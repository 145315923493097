module.exports = {
  title: 'Recore',
  logo: '',
  url: 'https://wolfcast.io',
  favicon: '../common/static/favicon/favicon.png',
  cover: '',
  legalName: 'Wolfcast Solutions UG (haftungsbeschränkt)',
  description: 'Recore is coming soon!',
  socialLinks: {
    twitter: '',
    github: '',
    linkedin: '',
    instagram: '',
    youtube: '',
    google: '',
  },
  googleAnalyticsID: '',
  themeColor: '#000696',
  backgroundColor: '#6496f6',
  social: {
    facebook: '',
    twitter: '',
  },
  address: {
    city: 'Jena',
    region: 'Thüringen',
    country: 'Germany',
    zipCode: '07743',
  },
  contact: {
    email: 'hello@robertwolf.dev',
    phone: '01734308312',
  },
  foundingDate: '2021',
  googleVerification: 'xxx',
};
